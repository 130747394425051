<script lang="ts" setup>
import SolidStar06 from '@docue/docue-ui-v2/icons/SolidStar06.vue'

// Temporary settings page for archive custom properties
definePageMeta({
  layout: 'settings',
  middleware: () => {
    const { isCurrentUserAdmin, workspace } = useAuth()
    if (!isCurrentUserAdmin.value || !workspace.value?.attributes.root_folder_id)
      return navigateTo('/settings', { external: true })
  },
})

const { data, isPending } = useQueryDocumentProperty.all()

const properties = computed(() => (data.value?.data ?? []))
const {
  isModalOpen: isCreateCustomPropertyModalOpen,
  openModal: openCreateCustomPropertyModal,
} = useModal()
const {
  isModalOpen: isEditCustomPropertyModalOpen,
  openModal: openEditCustomPropertyModal,
} = useModal()
const propertyToEdit = ref<App.Data.Document.Metadata.PropertyData>()

watch(propertyToEdit, property => !!property && openEditCustomPropertyModal())
watch(isEditCustomPropertyModalOpen, (isOpen) => {
  if (!isOpen)
    propertyToEdit.value = undefined
})
</script>

<template>
  <LazyDocumentsCustomPropertyCreateModal
    v-if="isCreateCustomPropertyModalOpen"
    v-model="isCreateCustomPropertyModalOpen"
  />
  <LazyDocumentsCustomPropertyEditModal
    v-if="isEditCustomPropertyModalOpen"
    v-model="isEditCustomPropertyModalOpen"
    :property="propertyToEdit"
  />
  <div
    class="flex flex-col gap-1 rounded-xl border border-gray-200 bg-white p-4"
  >
    <div class="flex justify-between">
      <span class="font-semibold">{{ $t('settings.custom-property.title') }}</span>
      <FeatureBlocker
        #default="{ action, enabled }"
        purchasable
        feature="archive-custom-properties"
      >
        <DTButton
          variant="link"
          dense
          @click="action(() => openCreateCustomPropertyModal())"
        >
          {{ $t('settings.custom-property.add-btn') }}
          <SolidStar06
            v-if="!enabled"
            class="size-4 text-yellow-400"
          />
        </DTButton>
      </FeatureBlocker>
    </div>
    <span class="w-[70%] text-sm text-gray-500">
      {{ $t('settings.custom-property.description') }}
    </span>
    <div class="flex flex-wrap gap-2.5 pt-3">
      <DTLoaderDots v-if="isPending" />
      <span
        v-else-if="!properties.length"
        class="text-xs text-gray-400"
      >{{ $t('settings.custom-property.undefined-message') }}</span>
      <template v-else>
        <DocumentsCustomPropertyTag
          v-for="property in properties"
          :key="property.id"
          :property="property"
          @select="propertyToEdit = property"
        />
      </template>
    </div>
  </div>
</template>
