import { queryKeys } from './useQueryDocumentProperty'
import { useFetchDocumentProperty } from './useFetchDocumentProperty'

const create = () => {
  const { create } = useFetchDocumentProperty()
  const queryClient = useQueryClient()

  return useMutation(
    {
      mutationFn: create,
      onMutate: (variables) => {
        queryClient.cancelQueries({
          queryKey: queryKeys.all(variables).queryKey,
        })
      },
      onSettled: (_, _1, variables) => {
        queryClient.invalidateQueries({
          queryKey: queryKeys.all(variables).queryKey,
        })
      },
    },
  )
}

const update = () => {
  const { update } = useFetchDocumentProperty()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: update,
    onMutate: (variables) => {
      queryClient.cancelQueries({
        queryKey: queryKeys.all(variables).queryKey,
      })
    },
    onSettled: (_, _1, variables) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.all(variables).queryKey,
      })
    },
  })
}

const drop = () => {
  const { drop } = useFetchDocumentProperty()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: drop,
    onMutate: (variables) => {
      queryClient.cancelQueries({
        queryKey: queryKeys.all(variables).queryKey,
      })
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.all(variables).queryKey,
      })
    },
  })
}

export const useMutationDocumentProperty = {
  update,
  create,
  drop,
}
